import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private _headerclasses = new BehaviorSubject('');
  private headerclasses = this._headerclasses.asObservable();

  constructor() {}

  getHeaderClasses() {
    return this._headerclasses.asObservable();
  }

  setHeaderClasses(classes: string) {
    this._headerclasses.next(classes);
  }
}
