<main *transloco="let t">
  <header class="splash">
    <div class="background-image">
      <img
        src="assets/img/220207_sharelog_netvote_header.jpg"
        class="mobile-hidden"
        alt=""
      />
      <img
        src="assets/img/220207_sharelog_netvote_header_mobile.jpg"
        class="desktop-hidden"
        alt=""
      />
    </div>
    <div class="text">
      <h1>{{ t("netvote.title") }}</h1>
      <h2>{{ t("netvote.subtitle") }}</h2>
    </div>
  </header>
  <section class="center">
    <div class="col full">
      <div class="text center mint">
        <p class="large">
          {{ t("netvote.intro.paragraph1") }}
        </p>
        <p class="large">
          {{ t("netvote.intro.paragraph2") }}
        </p>
      </div>
    </div>
  </section>
  <section>
    <div class="col desktop-hidden">
      <div class="image">
        <img src="assets/img/segetis_software_netvote_2.jpg" alt="" />
      </div>
    </div>
    <div class="col">
      <div class="text">
        <h2>
          {{ t("netvote.advantages.1.title") }}
        </h2>
        <p>
          {{ t("netvote.advantages.1.paragraph") }}
        </p>
      </div>
    </div>
    <div class="col mobile-hidden">
      <div class="image">
        <img src="assets/img/segetis_software_netvote_2.jpg" alt="" />
      </div>
    </div>
  </section>
  <section>
    <div class="col">
      <div class="image">
        <img src="assets/img/segetis_netvote_easy-to-use.jpg" alt="" />
      </div>
    </div>
    <div class="col">
      <div class="text">
        <h2>
          {{ t("netvote.advantages.2.title") }}
        </h2>
        <p>
          {{ t("netvote.advantages.2.paragraph") }}
        </p>
      </div>
    </div>
  </section>
  <section>
    <div class="col desktop-hidden">
      <div class="image">
        <img src="assets/img/segetis_netvote_safe.jpg" alt="" />
      </div>
    </div>
    <div class="col">
      <div class="text">
        <h2>
          {{ t("netvote.advantages.3.title") }}
        </h2>
        <p>
          {{ t("netvote.advantages.3.paragraph1") }}
        </p>
        <p>
          {{ t("netvote.advantages.3.paragraph2") }}
        </p>
      </div>
    </div>
    <div class="col mobile-hidden">
      <div class="image">
        <img src="assets/img/segetis_netvote_safe.jpg" alt="" />
      </div>
    </div>
  </section>

  <section class="banner">
    <div class="text">
      <p>
        {{ t("netvote.contact.cta") }}
        <a href="tel:+41 41 541 91 09">+41 41 541 91 09</a>
        <span class="nobreak">{{ t("netvote.contact.hours") }}</span
        ><br />-<br />{{ t("netvote.contact.alternative") }}
        <a href="mailto:info@netvote.ch">info@netvote.ch</a>
      </p>
    </div>
  </section>
  <section
    id="virtual-ga"
    *transloco="let t; read: 'services.virtual-general-assembly'"
  >
    <div class="col">
      <div class="text">
        <h2 [innerHTML]="t('title')"></h2>
        <p>
          <b class="mint">{{ t("subtitle1") }}</b>
        </p>
        <p>
          {{ t("paragraph1") }}
        </p>
        <p>
          {{ t("paragraph2") }}
        </p>
        <p>
          <b class="mint">{{ t("subtitle2") }}</b>
        </p>
        <p>
          {{ t("paragraph3") }}
        </p>
        <p>
          {{ t("paragraph4") }}
        </p>
      </div>
    </div>
    <div class="col">
      <div class="image mobile-hidden">
        <img
          src="assets/img/segetis_services_virtual_general_assembly.jpg"
          alt=""
        />
      </div>
      <div class="image">
        <img
          src="assets/img/segetis_services_mixed_general_assembly.jpg"
          alt=""
        />
      </div>
    </div>
  </section>
</main>
<!--  <section class="banner" id="references" *ngIf="references" >-->
<!--    <p *transloco="let t;">-->
<!--      {{ t('home.references.clients') }}-->
<!--    </p>-->
<!--    <ng-container  *ngFor="let reference of references">-->
<!--      <a class="reference" *ngIf="reference.fields.url" [href]="reference.fields.url" target="_blank" rel="noopener">-->
<!--        <img [src]="reference.fields.logo.fields.file.url" alt="">-->
<!--      </a>-->
<!--      <div class="reference" *ngIf="!reference.fields.url">-->
<!--        <img [src]="reference.fields.logo.fields.file.url" alt="">-->
<!--      </div>-->
<!--    </ng-container >-->
<!--  </section>-->
<!--  <section id="services">-->
<!--    <div class="background-image">-->
<!--      <img src="./../../assets/img/segetis_home_services.jpg" alt="">-->
<!--    </div>-->
<!--    <div class="text">-->
<!--      <h2>-->
<!--        {{ t('navigation.services') }}-->
<!--      </h2>-->
<!--      <h3>-->
<!--        {{ t('home.services.title') }}-->
<!--      </h3>-->
<!--      <p>-->
<!--        {{ t('home.services.text') }}-->
<!--      </p>-->
<!--      <div class="button" routerLink="/services">-->
<!--        <div class="text">-->
<!--          <div class="one">-->
<!--            {{ t('home.services.button.1') }}-->
<!--          </div>-->
<!--          <div class="two">-->
<!--            {{ t('home.services.button.2') }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--  <section id="attributes">-->
<!--    <div class="card">-->
<!--      <div class="image">-->
<!--        <img src="./../../assets/img/segetis_flexible.jpg" alt="">-->
<!--      </div>-->
<!--      <div class="text-container">-->
<!--        <h3>-->
<!--          {{ t('home.attributes.1.title') }}-->
<!--        </h3>-->
<!--        <p>-->
<!--          {{ t('home.attributes.1.text') }}-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card">-->
<!--      <div class="text-container">-->
<!--        <h3>-->
<!--          {{ t('home.attributes.2.title') }}-->
<!--        </h3>-->
<!--        <p>-->
<!--          {{ t('home.attributes.2.text') }}-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="image">-->
<!--        <img src="./../../assets/img/segetis_sustainable.jpg" alt="">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card">-->
<!--      <div class="image">-->
<!--        <img src="./../../assets/img/segetis_responsible.jpg" alt="">-->
<!--      </div>-->
<!--      <div class="text-container">-->
<!--        <h3>-->
<!--          {{ t('home.attributes.3.title') }}-->
<!--        </h3>-->
<!--        <p>-->
<!--          {{ t('home.attributes.3.text') }}-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--  <section id="software">-->
<!--    <div class="background-image">-->
<!--      <img src="./../../assets/img/segetis_home_software.jpg" alt="">-->
<!--    </div>-->
<!--    <div class="text right-aligned">-->
<!--      <h2>-->
<!--        {{ t('navigation.software') }}-->
<!--      </h2>-->
<!--      <h3>-->
<!--        {{ t('home.software.title') }}-->
<!--      </h3>-->
<!--      <p>-->
<!--        {{ t('home.software.text') }}-->
<!--      </p>-->
<!--      <div class="button" routerLink="/software">-->
<!--        <div class="text">-->
<!--          <div class="one">-->
<!--            {{ t('home.software.button.1') }}-->
<!--          </div>-->
<!--          <div class="two">-->
<!--            {{ t('home.software.button.2') }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--  <section id="about">-->
<!--    <div class="background-image">-->
<!--      <img src="./../../assets/img/segetis_home_about.jpg" alt="">-->
<!--    </div>-->
<!--    <div class="text">-->
<!--      <h2>-->
<!--        {{ t('navigation.about') }}-->
<!--      </h2>-->
<!--      <h3>-->
<!--        {{ t('home.about.title') }}-->
<!--      </h3>-->
<!--      <p>-->
<!--        {{ t('home.about.text') }}-->
<!--      </p>-->
<!--      <div class="button" routerLink="/about">-->
<!--        <div class="text">-->
<!--          <div class="one">-->
<!--            {{ t('home.about.button.1') }}-->
<!--          </div>-->
<!--          <div class="two">-->
<!--            {{ t('home.about.button.2') }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--</main>-->
