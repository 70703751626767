// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Record<string, any> = {
  production: false,
  contentful: {
    spaceId: '8wg7xfema29v',
    apiKey: 'JjLTrghhVZTDCJ238uwH-jsxL9s8EhQYb8rz06O7WAU',
    collectionIds: {
      references: '2ehEJ2MCDsuxkMjJGCGd4W',
      home_references: '2nkRMenS2hTgveVEHWqSMU',
      links: '46OapbtiRCnd5x2M3soNgN',
      downloads: '3Uha2CpBZv10m7aQjOOZ2c',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
