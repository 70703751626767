import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../../header/src/lib/header.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.styl'],
})
export class HomeComponent implements OnInit {
  constructor(private header: HeaderService) {}

  ngOnInit(): void {
    const splash = document.getElementsByClassName('splash')[0];
    console.log(splash);
  }
}
