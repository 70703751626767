<footer *transloco="let t;">
  <div class="about">
    <div class="logo">
      <img src="assets/segetis_logo.svg" alt="segetis logo">
    </div>
    <p>
      {{ t('footer.text') }}
    </p>
  </div>
  <ng-container *ngIf="site === 'segetis'">
    <div class="col links">
      <p>
        <a routerLink="/services">{{ t('navigation.services') }}</a>
        <a routerLink="/software">{{ t('navigation.software') }}</a>
        <a routerLink="/unternehmen">{{ t('navigation.about') }}</a>
        <a routerLink="/kontakt">{{ t('navigation.contact') }}</a>
      </p>
      <p>
        <a routerLink="/downloads">{{ t('navigation.downloads') }}</a>
        <a routerLink="/links">{{ t('navigation.links') }}</a>
      </p>
    </div>
  </ng-container>
  <div class="col notes">
    <p>
      <a [href]="t('navigation.data_link')" target="_blank" rel="noopener">{{ t('navigation.data') }}</a><br>
      © segetis 2022
    </p>
  </div>
</footer>
