import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { TranslocoRootModule } from 'transloco-root.module';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FooterComponent],
  imports: [TranslocoRootModule, HttpClientModule, RouterModule],
  exports: [FooterComponent],
})
export class FooterModule {}
