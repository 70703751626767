import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ActivationStart } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { HeaderService } from './header.service';
import { TranslocoRootModule } from 'transloco-root.module';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl'],
})
export class HeaderComponent implements OnInit {
  classes = '';
  constructor(
    private header: HeaderService,
    private translate: TranslocoService
  ) {}

  @Input() showLanguageSelection: boolean = true;
  @Input() site: string;

  siteLanguage = 'de';

  ngOnInit(): void {
    this.header.getHeaderClasses().subscribe((classes) => {
      this.classes = classes;
    });
    this.siteLanguage = this.translate.getActiveLang();
  }

  switchLanguage(language: string) {
    this.translate.setActiveLang(language);
    this.siteLanguage = language;
  }
}
