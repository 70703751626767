<header [class]="classes" [class.fixed]="site === 'segetis'">
  <div class="logo" routerLink="/home">
    <ng-container *ngIf="site === 'segetis'">
      <img class="dark" src="assets/segetis_logo.svg" alt="">
      <img class="light" src="assets/segetis_logo_white.svg" alt="">
    </ng-container>
    <ng-container *ngIf="site === 'netvote'">
      <img class="dark" src="assets/netvote_logo.svg" alt="">
      <img class="light" src="assets/netvote_logo_white.svg" alt="">
    </ng-container>
    <ng-container *ngIf="site === 'aktienbuch'">
      <img class="dark" src="assets/aktienbuch_logo.svg" alt="">
      <img class="light" src="assets/aktienbuch_logo_white.svg" alt="">
    </ng-container>
  </div>
  <nav *transloco="let t; read: 'navigation'">
    <ng-container *ngIf="site === 'segetis'">
      <div class="navigation-link" [routerLink]="'/services'" routerLinkActive="active" >{{ t('services') }}</div>
      <div class="navigation-link" [routerLink]="'/software'" routerLinkActive="active" >{{ t('software') }}</div>
      <div class="navigation-link" [routerLink]="'/about'" routerLinkActive="active" >{{ t('about') }}</div>
      <div class="navigation-link" [routerLink]="'/contact'" routerLinkActive="active" >{{ t('contact') }}</div>
    </ng-container>
    <div class="language-selection" *ngIf="showLanguageSelection === true">
      <div class="language-option" (click)="switchLanguage('de')" [class.active]="siteLanguage=='de'">
        DE
      </div>
      <div class="language-option" (click)="switchLanguage('en')" [class.active]="siteLanguage=='en'">
        EN
      </div>
      <div class="language-option" (click)="switchLanguage('fr')" [class.active]="siteLanguage=='fr'">
        FR
      </div>
    </div>
  </nav>
</header>
