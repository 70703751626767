import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.styl'],
})
export class FooterComponent implements OnInit {
  @Input() site: string;

  constructor() {}

  ngOnInit(): void {}
}
